import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
// https://unlimited1-cl-isp.dps.live/carolinatv/carolinatv.smil/playlist.m3u8?WebM3UCL
// blob:https://m3u.cl/436e8049-d017-418a-b3df-677f2f00b925

function App() {
  const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);

  useEffect(() => {
    console.log('first')
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      console.log("👍", "beforeinstallprompt", event);
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);

  async function downloadApp() {
    console.log("👍", "butInstall-clicked");
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      console.log("oops, no prompt event guardado en window");
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    console.log("👍", "userChoice", result);
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }

  return (
    <div className="App">
      <header className="App-header">
        {isReadyForInstall && <button onClick={()=>downloadApp()}>Install PWA</button>}
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Marcuxo
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      {/* <audio autoplay controls="controls"> <source src="blob:https://m3u.cl/4ffa3e56-3886-40f9-99f2-a1f9bfa55199" /> </audio>
  <video autoplay controls="controls" src="https://unlimited1-cl-isp.dps.live/carolinatv/carolinatv.smil/playlist.m3u8?WebM3UCL" ></video> */}

  <video id="player" class="video-js vjs-default-skin" height="360" width="640" controls preload="none">
    <source src="https://unlimited1-cl-isp.dps.live/carolinatv/carolinatv.smil/playlist.m3u8?WebM3UCL" type="application/x-mpegURL" />
</video>
    </div>
  );
}

export default App;
